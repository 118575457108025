<script lang="ts">
  import Game from "./game/Game.svelte";
</script>

<main>
  <Game />
</main>

<style>
  main {
    height: 100%;
    width: 100%;
  }
</style>
